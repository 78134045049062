.logo{
    height: 30px;
    width: 30px;
    margin: .5rem;
}

.brand{
    font-size: x-large;
}

.app-container{
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 90vh;
    background-color: #f3f5fa;
}

.footer{
    background-color: #0d243e!important;
}

.navs{
    display: flex;
    justify-content: flex-end;
}

.footer-navs{
    color:white;
    text-decoration: underline;
    text-align: center;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}