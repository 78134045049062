.card-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.or-separator{
    display: flex;
    justify-content: center;
    width: 100% !important;
    align-items: center;
}

.center-text{
    text-align: center !important;
}

.or-separator > div{
    width: 100% !important;
    height: 1px;
    background-color: silver;
}

.or-separator > .form-text{
    margin: 0 !important;
    padding: .5rem !important;
}

.preferences-selection .row{
    padding-top: .5rem !important;
    border-bottom: 1px solid rgb(241 248 255);
}

.preferences-selection .row:nth-child(even){
    background-color:rgb(241 248 255) !important;
}

.preferences-selection button{
    width: 100%;
}

@media (min-width: 768px) {
    .half-width{
        width: 50%;
    }
    .preferences-selection button{
        width: 50%;
    }
    .contact-update-footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}