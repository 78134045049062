.App {
  text-align: center;
}

body{ 
  min-height: 100vh; 
  
  display: grid;
  grid-template-rows: auto 1fr auto;
}

p{
  margin: 0 !important;
}

footer{ 
  min-height:50px; 
}

span{
  color:red;
}

a { 
  color: inherit !important;
}

.full-width{
  width: 100%;
}

.margin-top{
  margin-top: .5rem !important;
}

.margin-bottom{
  margin-bottom: .5rem !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-padding{
  padding: 0px !important;
}

.center-text{
  text-align: center;
}

@media (min-width: 800px) {
  .view-order-card{
    max-width: 50vw;
  }
  .view-order-card-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.line{
  height: 1px;
  background-color: rgb(214, 214, 214) !important;
  color: rgb(214, 214, 214) !important;
}

.line-wrapper{
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
